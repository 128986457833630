<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Egreso Administrativo - Ver</strong>

            <router-link  class="btn btn-light float-right btn-sm"  :to="{ path: '/egreso-administrativo/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off">
              <b-row>

                <b-col md="4">
                  <b-form-group>
                    <label>Cliente:</label>
                    <v-select placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="text" v-model="client" disabled></v-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Código:">
                    <b-form-input type="text" disabled  class="text-center" v-model="expense.code"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo:">
                    <b-form-select disabled v-model="expense.id_expense_method" :options="expense_method"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha:">
                    <b-form-input disabled type="date" class="text-center" :min="expense.now_date" v-model="expense.date"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Metodo de Pago:">
                    <b-form-select disabled v-model="expense.payment_method" :options="payment_method"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="10">
                  <b-form-group label="Observación:">
                    <b-form-textarea disabled v-model="expense.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Total:">
                    <b-form-input disabled type="number" step="any" class="text-right" v-model="expense.total"></b-form-input>
                  </b-form-group>
                </b-col>
 
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import DataCollection from "@/assets/js/DataCollection";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props: ["id_expense"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Expense',
      role: 5,
      expense: {
        id_expense:'',
        id_user:'',
        id_client:'',
        id_branch_office:'',
        id_expense_method:'',
        code:'',
        payment_method:'008',
        date:moment(new Date()).local().format("YYYY-MM-DD"),
        now_date: moment(new Date()).local().format("YYYY-MM-DD"),
        observation:'',
        total:'',
        state:'',
      },
      // payment_method:[
      //   {value:'008', text:'EFECTIVO'},
      //   {value:'007', text:'CHEQUES'},
      //   {value:'001', text:'DEPÓSITO EN CUENTA'},
      //   {value:'003', text:'TRANSFERENCIA DE FONDOS'},
      // ],
      client: null,
      clients: [],
      expense_method:[],
      errors: {
        id_client: false,
        code: false,
        payment_method: false,
        date: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListExpenseMethod();
    this.ViewExpense();
  },
  methods: {
    ListExpenseMethod,
    ViewExpense,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    payment_method: function () {
      return DataCollection.MethodPayment();
    }
  },
};

function ViewExpense() {
  let me = this;
  let id_expense = je.decrypt(this.id_expense);
  let url = me.url_base + "expense/view/"+id_expense;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.expense.id_expense = response.data.result.id_expense;
        me.expense.id_client = response.data.result.id_client;
        me.expense.id_user = response.data.result.id_user;
        me.expense.id_branch_office = response.data.result.id_branch_office;
        me.expense.id_expense_method = response.data.result.id_expense_method;
        me.expense.code = response.data.result.code;
        me.expense.payment_method = response.data.result.payment_method;
        me.expense.date = response.data.result.date;
        me.expense.observation = response.data.result.observation;
        me.expense.total = response.data.result.total;
        me.expense.state = response.data.result.state;
        me.client = { value: response.data.result.id_client, text: response.data.result.client_name }
      }
    });
}

function ListExpenseMethod() {
  let me = this;
  let url = me.url_base + "income/list-expenses-method";
  me.expense_method = [{ value: '', text:'- Seleccione una opcion - '}];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.expense_method.push({value: element.id_expense_method, text: element.name });
      });
    }

  });
}
</script>
